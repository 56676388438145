.list-item {
  cursor: pointer;
  padding: 15px 20px 15px 30px;
  min-height: 45px;
  max-height: 65px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  &.followed {
    .item-title {
      color: rgba(76, 76, 82, 0.7);
    }
  }

  &:hover {
    background: #f7f8f8;
    transition: all 0.15s ease;

    .item-title {
      color: #1f2027;
    }
  }

  &.disabled {
    cursor: initial;
    pointer-events: none;

    &:hover {
      background: unset;
      color: unset;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  img {
    position: absolute;
  }

  .item-title {
    font-size: 14px;
    line-height: 19px;
    font-weight: 600;
    color: rgb(76, 76, 82);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .item-subtitle {
    color: rgb(119, 119, 119);
    font-size: 14px;
    line-height: 17px;
    margin-top: 1px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .badge {
    position: absolute;
    right: 20px;

    .yellow-badge {
      background-color: #ffee95;
      color: rgb(76, 76, 82);
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      min-width: 24px;
      height: 24px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1em;
      padding: 0 8px;
      letter-spacing: 0.03em;
    }

    i {
      margin: 0;
    }
  }

  &.selected {
    background-color: rgb(76, 76, 82);
    .item-title {
      color: rgb(255, 255, 255);
      margin-left: 25px;
      font-weight: 600;
    }

    .item-subtitle {
      color: rgb(221, 221, 221);
      margin-left: 25px;
    }
  }
}
