.mark-as-seen {
  cursor: pointer;
}

.message-detail {
  position: relative;
  width: 100%;
  min-width: 800px;
  box-shadow: 0 4px 4px rgba(221, 221, 221, 0.1);
  border-radius: 10px;

  &.seen {
    .slick-current &,
    .message-detail-wrapper & {
      opacity: 0;
      animation: slideUpAndFadeOut 0.4s cubic-bezier(0.77, 0, 0.175, 1) both;
    }
  }

  &.unseen {
    bottom: 0;
  }

  &.next {
    .message-header {
      .mark-as-read-button {
        opacity: 0;
      }
    }
  }
}

.fade-in {
  animation: fadeIn 0.4s cubic-bezier(0.77, 0, 0.175, 1) both;
  transition: ease-in;
}

@keyframes slideUpAndFadeOut {
  0% {
    opacity: 1;
    bottom: 0;
  }
  100% {
    opacity: 1;
    bottom: 1000px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.6;
  }
  25% {
    opacity: 0.75;
  }
  75% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
