.teams-workspace-picker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .workspaces {
    display: flex;
    width: 30%;
    margin-top: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .workspace {
      width: 100%;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e9e9e9;
      font-size: 1.25em;

      .detail {
        display: flex;
        width: 70%;
        justify-content: flex-start;
        align-items: center;

        img {
          height: 70px;
          width: 70px;
        }
      }
    }
  }
}
