.message-header {
  height: 110px;
  background: #fff;
  border-radius: 0.5em 0.5em 0 0;
  border: solid 1px #e9e9e9;
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  align-items: flex-end;

  .message-detail-title {
    font-weight: 700;
    font-size: 1.3em;
    line-height: 1.2;

    a {
      color: #1f2027;

      &:hover {
        opacity: 0.8;
        transition: opacity 0.15s ease;
      }
    }

    i.remove-message {
      color: #e9e9e9;
      margin-left: 5px;
      cursor: pointer;

      &:hover {
        color: #d0021b;
        transition: color 0.15s ease;
      }
    }
  }

  .message-subtitle {
    display: flex;
    align-items: center;
    margin-top: 3px;

    img.profile-picture {
      border-radius: 100%;
      border: solid 1px #e9e9e9;
      width: 26px;
      margin-right: 7px;
    }

    .message-header-item {
      color: #777777;
      font-size: 1em;
    }
  }

  .mark-as-read-button,
  .mark-as-read-button:hover {
    background-color: #21ba45 !important;
    color: #ffffff !important;
    i {
      background-color: transparent !important;
      color: #ffffff !important;
    }
  }

  .mark-as-read-button:hover {
    background-color: darken(#21ba45, 5%) !important;
  }
}
