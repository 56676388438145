.emoji-picker-container {
  display: inline-flex;
}

.ui.popup.emoji-popup {
  box-shadow: 0 4px 4px rgba(221, 221, 221, 0.15);
  border: solid 1px #e9e9e9;

  &:before {
    box-shadow: 1px 1px 0px 0px #e9e9e9;
  }
}

.emojis {
  font-size: 24px;

  span {
    cursor: pointer;
    padding: 4px;
    display: inline-flex;
    width: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    &:hover {
      background: #f4f4f4;
      transition: background-color 0.15s ease;
    }
  }
}

.current-emoji {
  font-size: 24px;
  margin-right: 20px;
  cursor: pointer;
  &.animate {
    animation: currentEmoji 0.4s ease both;
  }
}

.emoji-reaction {
  width: 24px;
  margin-right: 20px;
  cursor: pointer;
}

.current-emoji,
.emoji-reaction {
  &:hover {
    filter: brightness(90%);
  }
}

@keyframes currentEmoji {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
