.catchup-container {
  display: flex;
  width: 100%;
  min-height: 100vh;

  .left-panel {
    width: 350px;
    position: fixed;
    overflow: auto;
    z-index: 1;
  }

  .right-panel {
    width: calc(100% - 350px);
    margin-left: 350px;
    min-width: 350px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: relative;

    .caught-up-message {
      img {
        width: 500px;
      }

      h1 {
        text-align: center;
        font-size: 24px;
        margin: 30px 0;
      }

      .controls {
        display: flex;
        justify-content: center;

        a {
          color: #1f2027;
        }

        button {
          background: white;
        }
      }
    }
  }
}

.loading-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  .signal-loader {
    position: unset !important;
    margin-left: 55px !important;
  }
}
