.signaled-message-container {
  display: flex;
  width: 100%;
  min-height: 100vh;

  .left-panel {
    width: 350px;
    min-width: 350px;
    height: 100vh;
    position: fixed;

    .nav {
      border-bottom: none;
    }
  }

  .right-panel {
    width: calc(100% - 350px);
    margin-left: 350px;
    min-width: 350px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0 0 80px 0;

    .message {
      height: 75%;
      margin: auto;

      .message-detail {
        max-width: 880px;
      }
    }

    button.follow {
      color: rgb(47, 180, 87);
      background-color: transparent;
      border-color: rgb(47, 180, 87);

      &:hover {
        background-color: rgb(47, 180, 87);
        border-color: transparent transparent rgb(35, 140, 67);
        box-shadow: rgb(35, 140, 67) 0px -1px 0px 0px inset;
        color: #ffffff;
        transition: all 0.15s ease;
      }
    }

    button.following {
      width: 100px;
      margin: 0 !important;
      font-weight: 700;
      border: none;
      padding: 0 20px;
      height: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(47, 180, 87);
      color: rgb(255, 255, 255);

      span {
        font-size: unset;
        font-weight: unset;
        color: unset;
      }

      span.unfollow {
        display: none;
      }

      &:hover {
        span.unfollow {
          display: unset;
        }
        span.following {
          display: none;
        }

        background-color: rgb(208, 2, 27);
        border-color: transparent transparent rgb(181, 6, 27);
        box-shadow: rgb(181, 6, 27) 0px -1px 0px 0px inset;
        transition: all 0.15s ease;
      }
    }
  }

  .loading-message {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    .signal-loader {
      position: unset !important;
      margin-left: 55px !important;
    }
  }
}
