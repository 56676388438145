.nav-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  height: 100vh;
  border-right: solid 1px #e9e9e9;
  box-shadow: 4px 0 4px rgba(221, 221, 221, 0.1);

  .header {
    padding: 20px 30px;
    border-bottom: 1px solid #e9e9e9;
  }

  .title {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 0px;
    font-weight: 600;
  }

  .subtitle {
    font-size: 14px;
    line-height: 19px;
    color: #777;
  }

  .nav {
    height: 290px;
    padding-top: 30px;
    border-bottom: solid 1px #e9e9e9;
    flex-shrink: 0;

    #logo {
      height: 50px;
      position: relative;
      left: 44px;
      display: block;
    }

    .nav-links {
      margin: 25px 0 0 24px;

      span > div {
        height: 50px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        margin-bottom: 5px;
        width: fit-content;
        border-radius: 24px;

        a {
          display: flex;
          align-items: center;
          height: 100%;
        }

        &:hover {
          background: #f7f8f8;
          transition: all 0.15s ease;
        }

        &.active {
          background: #f4f4f5;
          transition: all 0.15s ease;
        }

        img {
          width: 20px;
          margin-right: 15px;
        }

        span {
          font-size: 24px;
          color: #35343c;
          font-weight: 700;
        }
      }
    }
  }

  .feedback {
    border-top: 1px solid #e9e9e9;
    min-height: 60px;
    display: flex;
    justify-content: center;

    a {
      width: 100%;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #4c4c52;
      cursor: pointer;

      i {
        width: 12px;
      }
    }
    &:hover {
      background: #f7f8f8;
      transition: all 0.15s ease;
      a {
        color: #1f2027;

        i {
          background: #4c4c52;
        }
      }
    }
  }

  .profile-container {
    margin-top: auto;
    height: 80px;

    &:hover,
    &.active {
      background: #f7f8f8;
      transition: all 0.15s ease;

      span.username {
        color: #1f2027;
      }
    }
  }
  .logout-button {
    padding: 16px;
  }

  .content {
    height: 70%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
}
