.profile {
  height: 100%;
  display: flex;
  align-items: center;

  .dropdown {
    height: 100%;
    width: 100%;

    &.active {
      background: #f8f8f8;
    }
  }

  i.dropdown {
    display: none;
  }

  .menu {
    width: 80%;
    left: 20% !important;
  }

  .dropdown-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  i.logout-icon {
    background: rgb(208, 2, 27) !important;
  }

  .logout-button {
    color: rgb(208, 2, 27) !important;
    padding: 0 !important;
  }

  border-top: 1px solid #e9e9e9;

  .profile-trigger {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 0 0 20px;
    align-items: center;

    .profile-info {
      display: flex;
      width: calc(100% - 50px);
      padding-left: 15px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .profile-picture {
      display: inline-block;
      width: 42px;
      height: 42px;
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border: 1px solid #e9e9e9;
    }

    .username {
      font-size: 1em;
      line-height: 1.3;
      font-weight: 600;
      color: #4c4c52;
    }

    .workspace {
      font-size: 0.875em;
      line-height: 1.4;
      color: #777777;
    }
  }
}
