.slides-message {
  border: solid 1px #e9e9e9;
  height: 545px;

  .slides-iframe {
    width: 100%;
    height: 100%;
    border: none;

    .punch-viewer-body-v2 {
      background: white;
    }
  }
}
