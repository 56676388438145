.ui.popup.user-profile-popover {
  padding: 0 !important;
  box-shadow: 0 2px 4px rgba(221, 221, 221, 0.4) !important;
  border: 1px solid #e9e9e9 !important;

  &.bottom:before {
    box-shadow: -1px -1px 0px 0px #e9e9e9 !important;
  }

  &.top:before {
    box-shadow: 1px 1px 0px 0px #e9e9e9 !important;
  }
}

.user-profile-card {
  background-color: #fff;
  height: 45px;
  min-width: 140px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  position: relative;
  z-index: 3;
  user-select: none;

  .profile-picture {
    display: inline-block;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: none;
    border-right: solid 1px #e9e9e9;
  }

  .user-detail {
    background-color: #fff;
    width: 100%;
    border-top-right-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    .user-name {
      font-size: 1em;
      font-weight: 600;
      font-family: "Lato";
      color: #4c4c52;
      letter-spacing: 0;
    }
  }
}
