.getting-started-container {
  display: flex;
  width: 100%;
  min-height: 100vh;

  .left-panel {
    width: 350px;
    min-width: 350px;
    height: 100vh;
    position: fixed;

    .nav {
      border-bottom: none;
    }

    .header {
      display: none;
    }
  }

  .right-panel {
    width: calc(100% - 350px);
    margin-left: 350px;
    min-width: 350px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0 0 80px 0;

    button {
      a,
      a:hover {
        color: #1f2027;
      }
    }

    .help-text {
      margin: 2em 6em 0 6em;
      width: 100%;
      height: 100%;
      padding: 70px 40px;
      background-color: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
      min-width: 800px;
      max-width: 880px;
      border: solid 1px #e9e9e9;
      border-radius: 0.5em;
      box-shadow: 0 4px 4px rgba(221, 221, 221, 0.1);

      hr {
        border: none;
        border-bottom: solid 1px #eee;
        width: 100%;
        margin: 40px 0 0 0;
      }

      div.section {
        display: flex;
        align-items: center;
        flex-direction: column;

        h1 {
          font-size: 1.25em;
          font-weight: 600;
          margin: 0 0 10px 0;
          min-width: 40rem;
          color: #1f2027;
        }

        p {
          color: #4C4C52;
          max-width: 40rem;
        }

        &.hero {
          h1 {
            margin: 0 0 15px 0;
          }

          p {
            font-size: 1.125em;
          }
        }

        iframe {
          min-width: 45rem;
          margin: 10px 0 0 0;
        }

        .feedback-btn {
          align-self: flex-start;
        }
      }

      .section:not(:first-child) {
        margin-top: 40px;
      }

      iframe {
        width: 100%;
        height: 545px;
        border: 0;
      }
    }
  }
}
