.message-viewer-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  width: 100%;
  max-width: 880px;

  .message-content {
    white-space: pre-wrap;
    word-break: break-word;
  }

  .author {
    font-weight: bold;
  }

  .message-detail {
    &.next {
      opacity: 0.6;
      pointer-events: none;

      .toolbar {
        pointer-events: none;
      }

      &.activate {
        position: relative;
        animation: slideUpAndFadeOut 0.4s cubic-bezier(0.77, 0, 0.175, 1) both;
        opacity: 1;
      }
    }

    &:not(:first-child) {
      margin-top: 40px;
    }

    .text-background {
      height: auto;

      .text-message {
        min-height: 545px;
      }
    }
  }

  .caught-up-message {
    margin: auto;
    // Let's try with no animations for now
    // animation: caughtUpMessage 0.6s cubic-bezier(0.77, 0, 0.175, 1) both;
    // transform: scale(0.8);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: -40px;

    img {
      width: 500px;
    }

    h1 {
      text-align: center;
      font-size: 24px;
      margin: 30px 0;
    }

    .controls {
      display: flex;
      justify-content: center;

      a {
        color: #1f2027;
      }

      button {
        background: white;
      }
    }
  }

  .no-messages-message {
    display: flex;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}

@keyframes caughtUpMessage {
  0% {
    transform: scale(0.4);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(0.8);
  }
}
