.browse-container {
  display: flex;
  width: 100%;
  min-height: 100vh;

  .left-panel {
    width: 350px;
    min-width: 350px;
    height: 100vh;
    position: fixed;

    .list-item {
      height: 45px;

      .item-title {
        font-weight: 400;
      }
      &.selected {
        img {
          display: none;
        }

        .item-title {
          margin-left: 0;
          font-weight: 600;
        }

        .item-subtitle {
          margin-left: 0;
        }
      }
    }

    .section-header {
      width: 100%;
      background-color: #f9f9f9;
      color: #777777;
      border-top: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
      padding: 0 30px;
      font-size: 14px;
      font-weight: 600;
      height: 40px;
      display: flex;
      align-items: center;
      margin: 5px 0 0 0;
    }
  }

  .right-panel {
    width: calc(100% - 350px);
    margin-left: 350px;
    min-width: 350px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 0 0 80px 0;

    .top-bar {
      min-height: 75px;

      button.follow {
        color: rgb(47, 180, 87);
        background-color: transparent;
        border-color: rgb(47, 180, 87);

        &:hover {
          background-color: rgb(47, 180, 87);
          border-color: transparent transparent rgb(35, 140, 67);
          box-shadow: rgb(35, 140, 67) 0px -1px 0px 0px inset;
          color: #ffffff;
          transition: all 0.15s ease;
        }
      }

      button.following {
        width: 100px;
        margin: 0 !important;
        font-weight: 700;
        border: none;
        padding: 0 20px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        background-color: #2fb457;
        border-color: #0000 #0000 #238c43;
        box-shadow: #238c43 0px -1px 0px 0px inset;

        span {
          font-size: unset;
          font-weight: unset;
          color: unset;
        }

        span.unfollow {
          display: none;
        }

        &:hover {
          span.unfollow {
            display: unset;
          }
          span.following {
            display: none;
          }

          background-color: rgb(208, 2, 27);
          border-color: transparent transparent rgb(181, 6, 27);
          box-shadow: rgb(181, 6, 27) 0px -1px 0px 0px inset;
          transition: all 0.15s ease;
        }
      }
    }
  }

  .message-subtitle {
    .message-header-item {
      &.channel-name {
        display: none;
      }
    }
  }
  .message-viewer-wrapper {
    padding: 2em 6em 0 6em;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
