.message-player {
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 60px 0 0 0;

  .message-content {
    white-space: pre-wrap;
    word-break: break-word;
  }

  .author {
    font-weight: bold;
  }

  .caught-up-message {
    margin: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    top: -30px;

    img {
      width: 500px;
    }

    h1 {
      text-align: center;
      font-size: 24px;
      margin: 30px 0;
    }

    .controls {
      display: flex;
      justify-content: center;

      a {
        color: #1f2027;
      }

      button {
        background: white;
      }
    }
  }
}

.slick-slider {
  padding: 20px 0;
  width: 100%;
  outline: none;
  user-select: initial;

  .slick-prev,
  .slick-next {
    top: 50%;
  }
}

.slick-slide {
  opacity: 0.6;
  transition: opacity 0.2s ease;

  .message-detail {
    pointer-events: none;
    margin: 0 20px;
    // transform: scale(0.99);
    transition: transform 0.4s ease;

    .mark-as-read-button {
      display: none;
    }
  }

  &:hover {
    opacity: 0.9;
    transition: opacity 0.2s ease;
    cursor: pointer;
  }

  &.slick-current {
    cursor: unset;
    opacity: 1;
    transition: opacity 0.2s ease;

    .message-detail {
      pointer-events: unset;
      // transform: scale(1.01);
      transition: transform 0.4s ease;

      .mark-as-read-button {
        display: unset;
      }
    }
  }
}

.slick-list {
  padding-left: 0px !important; // half partial
  padding-right: 0px !important; // half partial
  height: calc((100% - 40px));
  overflow: visible;

  .message-detail {
    width: 800px;
  }
}

div.slick-arrow {
  display: flex !important;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: all 0.2s ease;
  border: solid 1px #eee;
  // box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 4px rgba(221, 221, 221, 0.2);
  // background-color: #ffee95;
  background-color: #fff;
  border-radius: 50%;
  i {
    background-color: rgb(76, 76, 82);
    width: 12px;
    height: 12px;
  }

  &.slick-prev {
    i {
      position: relative;
      right: 0.5px;
    }
  }

  &.slick-next {
    i {
      position: relative;
      left: 1px;
    }
  }

  &:hover {
    background-color: #f4f4f4;
  }
}

.single-slide {
  width: calc(100vw - 350px);
  .message-detail {
    width: 800px;
  }
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .mark-as-read {
    font-size: 24px !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      filter: brightness(90%);
      transition: all 0.15s ease;
    }
  }
}
