.login-container {
  display: flex;
  flex-direction: column;
  padding: 40px 80px;
  height: 100vh;
  background-color: #f8f9fb;

  .top-nav {
    display: flex;
    justify-content: space-between;

    .links {
      a {
        margin: 0 40px 0 0;
        font-size: 1.125em;
        color: #37465a;

        &:last-of-type {
          margin: 0;
        }
      }
    }
  }

  .logo {
    height: 70px;
  }

  .signal-image {
    margin: 30px auto 50px auto;
    width: 100%;
    height: 50%;
    min-height: 300px;
    max-height: 450px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .copy {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1 {
      font-size: 36px;
      color: #2e3139;
      margin-bottom: 15px;
    }

    p {
      width: 100%;
      max-width: 35em;
      text-align: center;
      font-size: 1.25em;
      margin-bottom: 40px;
      color: #37465a;

      &.mobile-support {
        display: none;
      }
    }

    .actions {
      .sign-in-button {
        margin: 0 10px;
        img {
          height: 45px;
          background-size: contain;
          border-radius: 4px;

          &:hover {
            box-shadow: 0 1px 3px rgba(221, 221, 221, 0.4);
          }
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.7;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .login-container {
    padding: 20px;
    align-items: center;

    div.links {
      display: none;
    }

    .top-nav {
      justify-content: center !important;
    }

    .logo {
      height: 60px;
      margin: 40px 0 0 0;
    }

    .signal-image {
      min-height: unset;
      max-height: unset;
      height: 300px;
      margin: 0;
    }

    .copy {
      width: 100%;
      padding: 0 10px;

      h1 {
        font-size: 22px;
      }

      p {
        font-size: 16px;
        margin-bottom: 20px;

        &.mobile-support {
          display: unset;
        }
      }

      .actions {
        display: none;
      }
    }
  }
}
