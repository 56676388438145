.manage-container {
  display: flex;
  width: 100%;

  .left-panel {
    width: 350px;
    position: fixed;
    overflow: auto;
  }

  .right-panel {
    width: calc(100vw - 350px);
    margin-left: 350px;
    padding: 80px 40px 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    h1 {
      font-size: 1.5em;
      font-weight: 900;
    }

    .subscriptions-title {
      display: flex;
      width: 80%;
      padding: 20px 0;
      justify-content: flex-start;
    }

    .subscriptions {
      width: 80%;
      background-color: #ffffff;
      padding: 10px 40px;
      margin-bottom: 40px;
      border: 1px solid #eee;

      .followed-channel {
        padding: 10px 0;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          font-size: 1em;
          font-weight: 400;
        }

        &:last-child {
          border: none;
        }
      }

      .no-subscriptions-message {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-direction: column;

        .browse-button {
          margin-top: 20px;
          a {
            color: #1f2027;
          }
        }
      }
    }

    .admin {
      width: 80%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 20px 0;

      .controls {
        width: 100%;
        background: white;
        border: solid 1px #eee;
        padding: 40px;
        margin-bottom: 40px;

        .row {
          display: inline-flex;
          align-items: center;

          h3 {
            margin: 0;
          }

          .checkbox {
            margin-left: 20px;
          }
        }

        hr {
          margin: 20px 0;
          border-bottom: 1px solid #eee;
          border-top: none;
        }
      }
    }
  }
}
