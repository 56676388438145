.progress {
  width: 100%;
  height: 2px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;

  .bar {
    height: 100%;
    background-color: #ffd000;
    opacity: 0.6;
  }
}
