.toolbar {
  width: 100%;
  height: 45px;
  border: 1px solid #e9e9e9;
  border-top: none;
  background-color: #fff;
  padding: 0 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 0 0 0.5em 0.5em;

  .video-controls {
    flex: 1 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    *:not(:first-child) {
      margin: 0 16px;
    }

    i {
      background-color: #4c4c52;

      &:last-child {
        margin: 0;
      }
    }
  }

  .message-actions {
    display: flex;
    flex: 1 0 20%;
    justify-content: flex-end;
    justify-self: flex-end;
    align-items: center;
  }

  .source-logo {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 1;
    padding: 0 4px 0 24px;

    &:empty {
      display: none;
    }

    img {
      height: 20px;

      &.zoom {
        max-width: 50px;
      }

      &.youtube {
        max-width: 70px;
      }
    }
  }
}
