/**
  * NB
  * - Only place global styles here. Styles for individual components should live in each component's folder.
  * - normalize.css is first imported via NPM in index.ts
  */
@import url("https://rsms.me/inter/inter.css");

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

@font-face {
  font-family: "Lato";
  src: url("webfonts/LatoLatin-Semibold.eot");
  src: url("webfonts/LatoLatin-Semibold.eot?#iefix") format("embedded-opentype"),
    url("webfonts/LatoLatin-Semibold.woff2") format("woff2"),
    url("webfonts/LatoLatin-Semibold.woff") format("woff"),
    url("webfonts/LatoLatin-Semibold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "iA Writer Duo";
  src: url("webfonts/iAWriterDuoS-Regular.eot") format("embedded-opentype"),
    url("webfonts/iAWriterDuoS-Regular.woff2") format("woff2"),
    url("webfonts/iAWriterDuoS-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

body {
  font-family: "Lato", "sans-serif", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title {
  font-size: 20px;
  font-weight: bold;
}

.subtitle {
  color: rgb(119, 119, 119);
  font-size: 14px;
}

.user-mention {
  display: inline-block;
  font-family: "Inter";
  background: #fff6cb;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 600;
}

.custom-emoji img {
  height: 1.125em;
  display: inline-block;
}

.caught-up-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
}

.caught-up-message a {
  color: #1f2027;
}
