.text-background {
  border: solid 1px #e9e9e9;
  height: 545px;
  background: #ffffff;
  border-radius: 0 0 0.5em 0.5em;
  overflow: hidden;
  position: relative;

  &::before,
  &::after {
    display: block;
    position: absolute;
    left: 10%;
    width: 80%;
    content: "";
    z-index: 2;
  }

  &::before {
    background-image: linear-gradient(to top, #fff0 0, #fff 100%);
    top: 30px;
    height: 30px;
  }

  &::after {
    background-image: linear-gradient(to bottom, #fff0 0, #fff 100%);
    bottom: 30px;
    height: 40px;
  }

  &.no-gradient {
    &::before,
    &::after {
      display: none;
    }
  }

  .text-message {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    position: relative;

    .emoji {
      width: 1em;
      height: 1em;
      font-family: "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
        sans-serif;
    }

    .inner-container {
      overflow-y: auto;
      white-space: pre-wrap;
      word-break: break-word;
      max-height: 100%;
      z-index: 1;
      width: 100%;
      padding: 30px 0;

      &.short {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        div.prettified-text {
          font-size: 1.2em;
          text-align: center;
          letter-spacing: -0.0155em;
          font-weight: 500;
          padding-bottom: 0;
          max-width: 30em;
        }
      }

      div.prettified-text {
        margin: 0 auto;
        color: #4c4c52;
        font-size: 1em;
        line-height: 1.4;
        text-overflow: ellipsis;
        font-family: "Inter";
        font-weight: 400;
        letter-spacing: -0.011em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
          max-width: 35em;

          strong {
            font-weight: 600;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        blockquote {
          border-left: 3px solid #0000000d;
          padding: 0 0 0 1.2em;
          max-width: 30em;
          color: #777;
          text-align: left;

          strong {
            font-weight: 600;
          }
        }

        pre,
        code {
          font-family: "iA Writer Duo", "SF Mono", monospace;
          background-color: #f7f8f9;
          font-weight: 400;
          border-radius: 5px;
          color: #3d444a;
          letter-spacing: 0;
          padding: 1em;
          text-align: initial;
          overflow: auto;
        }

        pre {
          margin-top: 1em;
          font-size: 0.875em;
        }

        code {
          padding: 5px 3px;
          color: #e83878;
        }
      }
    }

    .background {
      z-index: 0;
      position: absolute;

      &.pattern-dots-sm {
        height: 400px;
        width: 400px;
        border-radius: 100%;
        color: #f0a1ba;
      }

      &.pattern-dots-md {
        left: 0;
        height: 80%;
        width: 70%;
        color: #b6ccde;
        opacity: 0.8;

        &::after {
          display: block;
          position: absolute;
          background-image: linear-gradient(to right, #fff0 0, #fff 100%);
          right: 0;
          height: 100%;
          width: 20%;
          content: "";
        }
      }

      &.pattern-diagonal-lines-sm {
        right: 10%;
        height: 80%;
        width: 40%;
        color: #b9edda;
        opacity: 0.5;
      }

      &.pattern-horizontal-lines-sm {
        left: 0;
        height: 100%;
        width: 100%;
        color: #eeeeee;

        &::after {
          display: block;
          position: absolute;
          background-image: linear-gradient(100deg, #fff0 0%, #fff 80%);
          height: 100%;
          width: 100%;
          content: "";
        }
      }
    }
  }
}
