.page-not-found-container {
  display: flex;
  width: 100%;
  min-height: 100vh;

  .left-panel {
    width: 350px;
    min-width: 350px;
    height: 100vh;
    position: fixed;
  }

  .right-panel {
    width: calc(100% - 350px);
    margin-left: 350px;
    min-width: 350px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0;

    .error-message {
      font-size: 1.25em;
      font-weight: 700;
      margin: 40px 0 0 0;
    }

    .error-subtitle {
      font-size: 1em;
      margin: 10px 0 0 0;
      text-align: center;
    }
  }
}
