.history-container {
  display: flex;
  width: 100%;
  min-height: 100vh;

  .left-panel {
    width: 350px;
    min-width: 350px;
    height: 100vh;
    position: fixed;

    .list-item {
      .badge {
        display: none;
      }
      &.selected {
        img {
          display: none;
        }

        .item-title {
          margin-left: 0;
        }

        .item-subtitle {
          margin-left: 0;
        }
      }
    }
  }

  .right-panel {
    width: calc(100% - 350px);
    margin-left: 350px;
    min-width: 350px;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 60px 40px 0 40px;

    .caught-up-message {
      img {
        width: 500px;
      }

      .error-message {
        font-size: 1.25em;
        font-weight: 700;
        margin: 40px 0 0 0;
      }

      .error-subtitle {
        font-size: 1em;
        margin: 10px 0 20px 0;
        text-align: center;
      }

      .controls {
        display: flex;
        justify-content: center;

        a {
          color: #1f2027;
        }

        button {
          background: white;
        }
      }
    }

    .paginator {
      margin: 20px 0;
    }
  }
}
