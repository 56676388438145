.top-bar {
  display: flex;
  width: 100%;
  background-color: #ffffff;
  padding: 15px 4em;
  min-height: 70px;
  border-bottom: solid 1px #e9e9e9;
  box-shadow: 0 4px 4px rgba(221, 221, 221, 0.1);
  justify-content: space-between;
  align-items: center;
  z-index: 3;

  .title-block {
    h1 {
      font-size: 1em;
      line-height: 1.2;
      font-weight: 600;
      margin: 0;
    }

    p {
      font-size: 0.875em;
      font-weight: normal;
      color: #777;
    }
  }

  span {
    font-size: 0.875em;
    font-weight: normal;
    color: #4c4c52;
  }
}
